<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Agenda</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tujuan"
                  name="tujuan"
                  required
                  type="text"
                  label="Tujuan"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tembusan"
                  name="tembusan"
                  required
                  type="text"
                  label="Tembusan"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.referensi"
                  name="referensi"
                  type="text"
                  label="Referensi"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Jenis Dokumen
                    <small style="color: red">*</small>
                  </label>
                  <div
                    :class="['col-md-9', { invalid: isInvalidJenisDokumen }]"
                  >
                    <Multiselect
                      placeholder="Pick at least one"
                      select-label="Enter doesn’t work here!"
                      :value="payload.valueJenisDokumen"
                      track-by="id"
                      label="text"
                      :options="optionJenisDokumen"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeJenisDokumen"
                      @close="onTouchJenisDokumen"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidJenisDokumen"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Sifat Dokumen
                    <small style="color: red">*</small>
                  </label>
                  <div
                    :class="['col-md-9', { invalid: isInvalidSifatDokumen }]"
                  >
                    <Multiselect
                      placeholder="Pick at least one"
                      select-label="Enter doesn’t work here!"
                      :value="payload.valueSifatDokumen"
                      track-by="id"
                      label="text"
                      :options="optionSifatDokumen"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeSifatDokumen"
                      @close="onTouchSifatDokumen"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidSifatDokumen"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nomor_dokumen"
                  name="nomor_dokumen"
                  type="text"
                  required
                  label="Nomor Dokumen"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tanggal_dokumen"
                  name="tanggal_dokumen"
                  type="date"
                  required
                  label="Tanggal Dokumen"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.perihal"
                  name="perihal"
                  type="text"
                  required
                  label="Perihal"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.keterangan"
                  name="keterangan"
                  type="text"
                  label="Keterangan"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >File</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <Upload
                      v-model="payload.file"
                      isImage
                      required
                      name="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success">Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "../../../components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "../../../components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.suratMemo;
    },
    optionJenisDokumen() {
      const jenisdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const jenisdokumenDropdown = [];
      if (jenisdokumen) {
        jenisdokumen.forEach((value) => {
          jenisdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return jenisdokumenDropdown;
    },
    optionSifatDokumen() {
      const sifatdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const sifatdokumenDropdown = [];
      if (sifatdokumen) {
        sifatdokumen.forEach((value) => {
          sifatdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return sifatdokumenDropdown;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidJenisDokumen() {
      return (
        this.isTouchedJenisDokumen &&
        this.payload.valueJenisDokumen.length === 0
      );
    },
    isInvalidSifatDokumen() {
      return (
        this.isTouchedSifatDokumen &&
        this.payload.valueSifatDokumen.length === 0
      );
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        tujuan: "",
        tembusan: "",
        referensi: "",
        valueJenisDokumen: [],
        valueSifatDokumen: [],
        nomor_dokumen: "",
        tanggal_dokumen: "",
        perihal: "",
        keterangan: "",
      },
      myFile: [],
      isTouchedJenisDokumen: false,
      isTouchedSifatDokumen: false,
      isDisabled: false,
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/suratMemo/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("suratMemo/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("suratMemo/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        pengirim: data.pengirim,
      };
    },
    onTouchJenisDokumen() {
      this.isTouchedJenisDokumen = true;
    },
    onTouchSifatDokumen() {
      this.isTouchedSifatDokumen = true;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeJenisDokumen(value) {
      this.payload.valueJenisDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueJenisDokumen = [];
    },
    onChangeSifatDokumen(value) {
      this.payload.valueSifatDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueSifatDokumen = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("suratMemo/onCancel");
    },
    submit() {
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }

      const payload = {
        tujuan: this.payload.tujuan,
        tembusan: this.payload.tembusan,
        referensi: this.payload.referensi,
        jenisdokumen: parseInt(this.payload.valueJenisDokumen.id),
        sifatdokumen: parseInt(this.payload.valueSifatDokumen.id),
        nomor_dokumen: this.payload.nomor_dokumen,
        tanggal_dokumen: this.payload.tanggal_dokumen,
        perihal: this.payload.perihal,
        keterangan: this.payload.keterangan,
        file: this.myFile[1],
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("suratMemo/submitEdit", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("suratMemo/submitAdd", data);
          }
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

